import {CameraModule} from './camera-module';
import {ImageProcessing} from './image-processing';
import {
    createApp,
    ref,
    computed,
    onMounted,
    onUnmounted,
    nextTick
} from 'https://unpkg.com/vue@3.5.11/dist/vue.esm-browser.js';  // 來取代cdn方式

if (typeof window.csrfToken === 'undefined') {
    window.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

const apiUrl = `${window.location.origin}${window.location.pathname}`;

const feedback_html = `<div class="">
            <div class="d-flex flex-column align-items-start">
              <h5 class="text-start text_indent">1. 您對這次AI換裝功能使用上滿意嗎？</h5>
              <label class="ms-4"><input type="radio" name="satisfaction" value="非常滿意"> 非常滿意</label>
              <label class="ms-4"><input type="radio" name="satisfaction" value="滿意"> 滿意</label>
              <label class="ms-4"><input type="radio" name="satisfaction" value="普通"> 普通</label>
              <label class="ms-4"><input type="radio" name="satisfaction" value="不滿意"> 不滿意</label>
              <label class="ms-4"><input type="radio" name="satisfaction" value="非常不滿意"> 非常不滿意</label><br>
            </div>
            <div class="d-flex flex-column align-items-start">
              <h5 class="text-start text_indent">2. 這次AI換裝功能，是否符合您原本的期望值呢？</h5>
              <label class="ms-4"><input type="checkbox" name="adjustments" value="是，符合預期"> 是，符合預期</label>
              <label class="ms-4 text-start text_indent lh-sm"><input type="checkbox" name="adjustments" value="否，效果與期望不同(房屋格局異常)"> 否，效果與期望不同(房屋格局異常)</label>
              <label class="ms-4 text-start text_indent"><input type="checkbox" name="adjustments" value="否，效果與期望不同(風格種類太少)"> 否，效果與期望不同(風格種類太少)</label>
              <label class="ms-4 text-start text_indent"><input type="checkbox" name="adjustments" value="否，效果與期望不同(完全無法使用)"> 否，效果與期望不同(完全無法使用)</label>
              <label class="ms-4 text-start text_indent"><input type="checkbox" name="adjustments" value="否，效果與期望不同(速度太慢)"> 否，效果與期望不同(速度太慢)</label>
              <label class="ms-4 text-start text_indent"><input type="checkbox" name="adjustments" value="其他，請補充在下一題做詳述"> 其他，請補充在下一題做詳述</label><br>
            </div>
            <div class="d-flex flex-column align-items-start">
              <h5 class="text-start text_indent">3. 有其他建議或是問題跟想法嗎？歡迎分享給我們！</h5>
              <textarea  class="ms-4" id="additional-feedback" placeholder="輸入想法與建議..." rows="3" style="width: 92%;"></textarea>
            </div>
          </div>`;

const $aiImg = createApp({
    setup() {
        const isLoading = ref(false);
        const makingLoading = ref(false);
        // 拍照上傳
        const videoEl = ref(null);
        const canvasEl = ref(null);
        const photoData = ref(null);
        const cameraActive = ref(false);
        const textCameraActive = computed(() =>
            cameraActive.value ? "關閉相機" : "開啟相機"
        );
        const textPhotoActive = computed(() =>
            photoData.value === null ? "拍照上傳" : " 重 拍　"
        );
        const textUpload = "上傳照片";

        const reflash_seconds = 3;
        const uuid = ref('');
        const room_type = ref('');
        const room_style = ref('');

        const roomStyles = ref({
            'Modern': '現代風格',
            'Christmas': '聖誕節',
            'Halloween': '萬聖節',
            'Summer': '夏天風格',
            'LunarNewYear': '新春過年',
            'JapaneseDesign': '日式風格',
            'Tropical': '熱帶風格',
            'Vintage': '復古風格',
            'Coastal': '海岸風格',
        });

        const roomTypes = ref({
            'living-room': '客廳',
            'bedroom': '臥室',
            'kitchen': '廚房',
            'bathroom': '浴室',
            'dining-room': '餐廳',
        });

        let stream = null;

        const startCamera = async () => {
            if (imageUrl.value !== "") {
                imageUrl.value = "";
            }
            try {
                stream = await CameraModule.startCamera(videoEl.value);
                cameraActive.value = true;
            } catch (err) {
                console.log("getUserMedia error", err);
            }
        };

        const stopCamera = () => {
            CameraModule.stopCamera(stream);
            cameraActive.value = false;
            photoData.value = null;
        };

        const toggleCamera = () => {
            if (isNotCompleted.value.length > 4) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "有超過五張圖片正在製圖中，請稍後再試!",
                });
                return;
            }

            if (cameraActive.value) {
                stopCamera();
            } else {
                startCamera();
            }
        };

        const takePhoto = () => {
            photoData.value = CameraModule.takePhoto(videoEl.value, canvasEl.value);
            isImgMaking.value = !photoData.value;
            CameraModule.stopCamera(stream);
        };

        const retakePhoto = () => {
            photoData.value = null;
            stopCamera();
            startCamera();
        };

        const handlePhotoAction = () => {
            if (photoData.value === null) {
                takePhoto();
            } else {
                retakePhoto();
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === "hidden" && cameraActive.value) {
                stopCamera();
            }
        };

        // 上傳照片後續流程
        const imageUrl = ref("");
        const roomType = ref("living-room");
        const roomStyle = ref("Modern");
        const AIImgUrl = ref("");
        const tmpImgUrls = ref([]);

        const sortedTmpImgUrls = computed(() => {
            const sort = tmpImgUrls.value.slice().reverse();
            const notCompleted = [];
            const completed = [];

            sort.forEach((img) => {
                if (img.status == 1) {
                    completed.push(img);
                } else {
                    notCompleted.push(img);
                }
            });

            return [...notCompleted, ...completed];
        });

        const isImgMaking = ref(false);
        const feedback = ref({});

        const isNotCompleted = computed(() =>
            tmpImgUrls.value.filter((img) => img.status == 0)
        );

        const timeUp = () => {
            setInterval(() => {
                updateTmpImgUrls();
            }, reflash_seconds * 1000);
        };

        const getRoomTypes = () => {
            roomTypes.value = {
                'living-room': '客廳',
                'bedroom': '臥室',
                'kitchen': '廚房',
                'bathroom': '浴室',
                'dining-room': '餐廳',
            };
            return roomTypes.value;
        };

        const getRroomStyles = () => {
            roomStyles.value = {
                'Modern': '現代風格',
                'Christmas': '聖誕節',
                'Halloween': '萬聖節',
                'Summer': '夏天風格',
                'LunarNewYear': '新春過年',
                'JapaneseDesign': '日式風格',
                'Tropical': '熱帶風格',
                'Vintage': '復古風格',
                'Coastal': '海岸風格',
            };
            return roomStyles.value;
        };

        const updateTmpImgUrls = () => {
            var index_type = 'getImgList';
            const response = fetch(apiUrl + '?index_type=' + index_type, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                },
                method: 'GET',
            }).then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        // 將錯誤訊息拋出
                        throw new Error(JSON.stringify(json));
                    });
                }
                return response.json(); // 轉換回應為 JSON 格式
            }).then((json) => {
                tmpImgUrls.value = json;
                json.forEach(image => {
                    if (image.uuid == uuid.value
                        && image.status == 1) {
                        makingLoading.value = false;
                        AIImgUrl.value = image.url.startsWith("http")
                            ? image.url
                            : new URL(image.url, `${window.location.origin}/`).href;
                    }
                });
            }).catch((error) => {
                try {
                    const errorObj = JSON.parse(error.message); // 將錯誤的字串解析為物件
                    if (errorObj.errors && Array.isArray(errorObj.errors)) {
                        // 如果有 errors，顯示每個錯誤訊息
                        errorObj.errors.forEach(message => {
                            $.notify(message, 'danger');
                        });
                    } else {
                        // 否則顯示通用錯誤訊息
                        $.notify(errorObj.message, 'danger');
                    }
                } catch (e) {
                    // 如果無法解析錯誤，顯示通用的錯誤訊息
                    $.notify('資料錯誤', 'danger');
                }
            });

        };

        const checkBeforeUpload = (event) => {
            if (isNotCompleted.value.length > 4) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "有超過五張圖片正在製圖中，請稍後再試!",
                });
                event.preventDefault(); // 阻止文件選擇對話框的打開
            }
        };

        const handleUploadImage = async (event) => {
            isLoading.value = true;
            isImgMaking.value = false;
            await nextTick();
            const file = event.target.files[0];
            if (file) {
                try {
                    const imageData = await ImageProcessing.handleUploadImage(file);
                    photoData.value = null;
                    imageUrl.value = imageData;
                    isLoading.value = false;
                } catch (error) {
                    isLoading.value = false;
                    $.notify('圖片上傳失敗', 'danger');
                }
            }
        };

        const handleTransformImg = () => {
            if (!imageUrl.value && !photoData.value) return;

            if (!isImgMaking.value) {
                isImgMaking.value = true;
            }
            const imgToTransform = photoData.value || imageUrl.value;

            if (imgToTransform) {
                if (uploadImageFetch(imgToTransform, roomType.value, roomStyle.value)) {
                    makingLoading.value = false;
                    AIImgUrl.value = '';
                    tmpImgUrls.value.push({
                        uuid: uuid.value,
                        url: "",
                        oldUrl: imgToTransform,
                        status: 0,
                        time: getDate(),
                        roomType: roomType.value,
                        roomStyle: roomStyle.value,
                        isDownloaded: false,
                    });
                } else {
                    makingLoading.value = false;
                }
            }
        };

        const getDate = () => {
            // 取得今天的日期
            const today = new Date();

            // 格式化日期為 YYYY-MM-DD
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份從 0 開始，所以要 +1
            const day = String(today.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        };

        // 將圖片及其他欄位轉為 FormData 並上傳
        const uploadImageFetch = async (imageFile, roomType, roomStyle) => {
            var update_type = 'updateImg';
            const formData = new FormData();
            formData.append("image", imageFile);
            formData.append("room_type", roomType);
            formData.append("room_style", roomStyle);

            makingLoading.value = true;
            try {
                const response = await fetch(apiUrl + '/create?update_type=' + update_type, {
                    headers: {
                        'X-CSRF-Token': csrfToken,
                        'Accept': 'application/json',
                    },
                    body: formData,
                    method: 'POST',
                });

                if (response.ok) {
                    const result = await response.json();
                    uuid.value = result.uuid;
                    return true;
                } else {
                    $.notify('圖片上傳失敗', 'danger');
                    return false;
                }
                makingLoading.value = false;
            } catch (error) {
                $.notify('資料錯誤', 'danger');
                makingLoading.value = false;
                return false;
            }
        }

        const handleImageClick = (image) => {
            stopCamera();
            isImgMaking.value = true;
            imageUrl.value = image.oldUrl;
            roomType.value = image.roomType;
            roomStyle.value = image.roomStyle;
            room_type.value = image.roomType;
            room_style.value = image.roomStyle;
            uuid.value = image.uuid;
            if (image.status == 1) {
                makingLoading.value = false;
                AIImgUrl.value = image.url.startsWith("http")
                    ? image.url
                    : new URL(image.url, `${window.location.origin}/`).href;
            } else {
                AIImgUrl.value = '';
            }
        };

        // 刪除圖片
        const handleDeleteImage = (uuid) => {
            Swal.mixin({
                customClass: {
                    cancelButton: "btn btn-outline-danger m-5",
                    confirmButton: "btn btn-outline-primary m-5",
                },
                buttonsStyling: false,
            })
                .fire({
                    icon: "warning",
                    title: "你確定要刪除嗎 ?",
                    text: "刪除後將無法找回",
                    showCancelButton: true,
                    confirmButtonText: "確定刪除",
                    cancelButtonText: "算了 !",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        fetch(apiUrl + '/' + uuid, {
                            headers: {
                                "X-Requested-With": "XMLHttpRequest",
                                'X-CSRF-TOKEN': csrfToken,
                                'Accept': 'application/json',
                            },
                            method: "DELETE"
                        }).then((res) => {
                            if (!res.ok) return Promise.reject(res);
                            return res.json();
                        }).then((json) => {
                            Swal.fire({
                                title: '成功刪除!',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            updateTmpImgUrls();
                            isImgMaking.value = false;
                            AIImgUrl.value = "";
                            imageUrl.value = "";
                            photoData.value = null;
                        }).catch((res) => {
                            Swal.fire({
                                title: '刪除失敗!',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        });
                    }
                });
        };

        const notify = (message, type) => {
            $.notify({
                    message: message
                },
                {
                    type: type,
                    allow_dismiss: false,
                    newest_on_top: false,
                    mouse_over: false,
                    showProgressbar: false,
                    spacing: 10,
                    timer: 500,
                    placement: {
                        from: 'top',
                        align: 'right'
                    },
                    offset: {
                        x: 30,
                        y: 30
                    },
                    delay: 1000,
                    z_index: 10000,
                    animate: {
                        enter: 'animated fadeIn',
                        exit: 'animated bounce'
                    }
                })
        }

        const runFeedback = async (isDownloadFile = true) => {
            const response = fetch(apiUrl + '/' + uuid.value + '?index_type=hav_feedback', {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': csrfToken,
                },
                method: 'GET',
            }).then(response => {
                const contentType = response.headers.get('Content-Type');
                if (response.ok) {
                    if (contentType && contentType.includes('application/json')) {
                        return response.json(); // 回傳 JSON
                    } else {
                        return response.blob(); // 回傳圖片
                    }
                } else {
                    throw new Error('Network response was not ok');
                }
            }).then(data => {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: "btn btn-outline-danger m-5",
                        confirmButton: "btn btn-outline-primary m-5",
                    },
                    buttonsStyling: false,
                });
                if (data instanceof Blob) {
                    const newWindow = window.open(apiUrl + '/' + uuid.value + '?type=ai', '_blank', 'noopener,noreferrer');
                    if (newWindow) {
                        newWindow.blur(); // 讓新視窗失焦
                        window.focus();   // 回到當前頁面
                    }
                    swalWithBootstrapButtons.fire({
                        title: '下載成功!',
                        showConfirmButton: false,
                        icon: "success",
                        timer: 2000,
                    });
                } else if (typeof data === 'object') {
                    swalWithBootstrapButtons
                        .fire({
                            title: "您寶貴的建議是我們優化跟成長的動力!!!",
                            html: feedback_html,
                            showCancelButton: true,
                            confirmButtonText: "提交",
                            cancelButtonText: "取消",
                            preConfirm: () => {
                                // 收集用戶輸入值
                                const satisfaction = document.querySelector(
                                    'input[name="satisfaction"]:checked'
                                )?.value;
                                const adjustments = Array.from(
                                    document.querySelectorAll('input[name="adjustments"]:checked')
                                ).map((checkbox) => checkbox.value);
                                const additionalFeedback = document.getElementById(
                                    "additional-feedback"
                                ).value;

                                if (!satisfaction) {
                                    Swal.showValidationMessage("請選擇一個滿意度評分");
                                }

                                return {
                                    satisfaction,
                                    adjustments,
                                    additionalFeedback,
                                };
                            },
                            allowOutsideClick: false,
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                fetch(apiUrl + '/' + uuid.value, {
                                    headers: {
                                        "X-Requested-With": "XMLHttpRequest",
                                        'X-CSRF-TOKEN': csrfToken,
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    method: "PATCH",
                                    body: JSON.stringify({
                                        'satisfaction': result.value.satisfaction,
                                        'adjustments': result.value.adjustments,
                                        'feedback': result.value.additionalFeedback,
                                        'room_type': room_type.value,
                                        'room_style': room_style.value,
                                    })
                                }).then((res) => {
                                    if (!res.ok) return Promise.reject(res);
                                    return res.blob();
                                }).then((blob) => {
                                    const newWindow = window.open(apiUrl + '/' + uuid.value + '?type=ai', '_blank', 'noopener,noreferrer');
                                    if (newWindow) {
                                        newWindow.blur(); // 讓新視窗失焦
                                        window.focus();   // 回到當前頁面
                                    }
                                    swalWithBootstrapButtons.fire({
                                        title: '下載成功!',
                                        text: "您的建議已收到，謝謝您的回饋。",
                                        showConfirmButton: false,
                                        icon: "success",
                                        timer: 2000,
                                    });

                                    updateTmpImgUrls();
                                    isImgMaking.value = false;
                                    AIImgUrl.value = "";
                                    imageUrl.value = "";
                                    room_type.value = "";
                                    room_style.value = "";
                                    photoData.value = null;
                                }).catch((res) => {
                                    if (res.json) {
                                        res.json().then((errorJson) => {
                                            const errorMessages = errorJson.error
                                                ? Object.values(errorJson.error).flat().join("<br>")
                                                : "發生未知錯誤，請稍後再試。";

                                            Swal.fire({
                                                title: '下載失敗!',
                                                html: errorMessages,
                                                icon: 'error',
                                                showConfirmButton: true,
                                            });
                                        });
                                    }
                                });

                                isImgMaking.value = false;
                                AIImgUrl.value = "";
                                imageUrl.value = "";
                                room_type.value = "";
                                room_style.value = "";
                                photoData.value = null;
                            }
                        });
                }
            }).catch(error => console.error('下載失敗:', error));
        };

        // 檔案型態下載(高畫質大圖)
        const downloadImage = async (url) => {
            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error("Network response was not ok");

                // 將圖片轉為 Blob
                const blob = await response.blob();

                // 將 Blob 轉為 URL
                const imageUrl = URL.createObjectURL(blob);

                // 創建一個隱藏的 <a> 標籤來觸發下載
                const a = document.createElement("a");
                a.href = imageUrl;
                // 不設定 a.download，讓瀏覽器自行決定檔名
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                // 釋放 URL
                URL.revokeObjectURL(imageUrl);

            } catch (error) {
                console.error("Failed to download the image:", error);
            }
        };

        const getStatusTxt = (status) => {
            switch (status) {
                case 1:
                    return '已完成';
                    break;
                case 3:
                case 500:
                    return '轉換錯誤';
                    break;
                case 2:
                default:
                    return '製圖中..';
                    break;
            }
        }

        const handleDownloadImg = async (hasFeedback = true) => {
            if (hasFeedback) {
                runFeedback(true)
            } else {
                const newWindow = window.open(apiUrl + '/' + uuid.value + '?type=ai', '_blank', 'noopener,noreferrer');
                if (newWindow) {
                    newWindow.blur(); // 讓新視窗失焦
                    window.focus();   // 回到當前頁面
                }
            }
        };
        onMounted(() => {
            getRoomTypes();
            getRroomStyles();
            updateTmpImgUrls();
            timeUp();
            document.addEventListener("visibilitychange", handleVisibilityChange);
        });

        onUnmounted(() => {
            stopCamera();
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        });

        const message = ref("Hello, Vue 3!");
        return {
            message,
            isLoading,
            makingLoading,
            cameraActive,
            textCameraActive,
            textPhotoActive,
            toggleCamera,
            textUpload,
            videoEl,
            canvasEl,
            photoData,
            startCamera,
            stopCamera,
            takePhoto,
            retakePhoto,
            handlePhotoAction,

            imageUrl,
            AIImgUrl,
            tmpImgUrls,
            sortedTmpImgUrls,
            roomType,
            roomStyle,
            checkBeforeUpload,
            handleUploadImage,
            handleTransformImg,
            handleImageClick,
            handleDownloadImg,
            handleDeleteImage,
            isImgMaking,
            feedback,
            getStatusTxt,
        };
    },
});

$aiImg.mount("#ai_img");

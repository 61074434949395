import {createApp, ref, computed, onMounted} from 'https://unpkg.com/vue@3.5.11/dist/vue.esm-browser.js';  // 來取代cdn方式

if (typeof window.csrfToken === 'undefined') {
    window.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

const urlParams = new URLSearchParams(window.location.search);
const apiUrl = `${window.location.origin}${window.location.pathname}`;

const $allocate_points = createApp({
    setup() {
        // 轉出加盟店、經紀人
        const userStore = ref({});
        const userList = ref({});

        const totalPoints = ref(0); // 目前點數
        const deductedPoints = ref(0);  // 扣除後點數
        const selectedStore = ref('');
        const selectedEmployee = ref('');

        // 轉入加盟店、經紀人
        const storeList = ref({});
        const employeeList = ref({});
        const selectedStoreList = ref('');

        let isPointError = false;
        let share_point = 0;

        let abortController = null;
        let abortController2 = null;

        /**
         * 變更轉出加盟店
         */
        const changeStoret = () => {
            const first_store = Object.values(userStore.value)[0]; // 獲取 userStore 的第一個加盟店
            const store_id = selectedStore.value;    // 選取加盟店,加盟店選單也同步更換
            const store = userStore.value[store_id];   // 選出來的加盟店,並不存在選單裡面
            if (store === undefined && first_store !== undefined) {
                selectedStore.value = first_store.store_id;   // 取選單第一個
            }
            getUserList();
        }

        /**
         * 選擇轉出經紀人
         */
        const changeEmployee = () => {
            // 這寫法是直接綁定索引鍵，不必for迴圈跑n筆資料比對(如果有2萬筆就會很慢，吃記憶體),直接決定該編號是否存在來判斷
            const employee_id = selectedEmployee.value;    // 選取加盟店,經紀人選單也同步更換
            const employee = userList.value[employee_id];   // 選出來的經紀人,並不存在選單裡面
            const first_employee = Object.values(userList.value)[0]; // 獲取 userList 的第一個員工
            if (employee === undefined && first_employee !== undefined) {
                selectedEmployee.value = first_employee.employee_id;   // 取選單第一個
            }
            totalPoints.value = Math.max(employee ? employee.point : (first_employee ? first_employee.point : 0), 0); // 直接判斷有沒有存在,沒有存在預設為 0
            getDeductedPoints(selectedEmployee.value);
        };

        /**
         * 清除分配的點數
         */
        const resetUsePoints = () => {
            Object.values(employeeList.value).forEach((employee) => {
                employee.use_point = '';
            });
            deductedPoints.value = totalPoints.value;
        };

        /**
         * 取得分配的點數
         *
         * @returns {number}
         */
        const getUseTotalPoints = () => {
            // 跑迴圈取得每個employee裡的use_point總和
            return Object.values(employeeList.value).reduce((sum, employee) => {
                // 確保 use_point 是數字，且為 0 或正數
                const usePoints = parseInt(employee.use_point) || 0;
                return sum + usePoints;
            }, 0); // 初始值為 0
        }

        /**
         * 分配點數預覽
         *
         * @param index
         */
        const previewPoint = (index) => {
            const employee = employeeList.value[index];
            let usePoints = parseInt(employee.use_point) || 0;
            let useTotalPoints = getUseTotalPoints();

            share_point = 0;
            if(isStore()){
                share_point = 51;
            }

            // 分配點數超過自己點數
            if (useTotalPoints > (totalPoints.value - share_point)) {
                isPointError = true;
                // 還原至該筆未分配前並指定可分配剩餘點數
                usePoints = (totalPoints.value - share_point) - (useTotalPoints - usePoints);
                employee.use_point = Math.max(usePoints, 0); // 更新輸入框中的值 (最小為零)
            }
            getDeductedPoints();
        };

        /**
         * 取得[扣除後點數]
         *
         * @returns {number|*}
         */
        const getDeductedPoints = () => {
            let useTotalPoints = getUseTotalPoints();
            deductedPoints.value = totalPoints.value - useTotalPoints;

            isPointError = false;
            if(isStore()){
                share_point = 51;
            }

            if (deductedPoints.value <= share_point) {
                isPointError = true;
                deductedPoints.value = share_point;
                // resetUsePoints();
            }
            return deductedPoints.value;
        }

        /**
         * 確定分配點數
         *
         * @param index
         */
        const updatePoint = (index) => {
            const employee = employeeList.value[index];
            let usePoints = parseInt(employee.use_point) || 0;
            if (usePoints > totalPoints.value) {
                Swal.fire({
                    icon: 'warning',
                    title: '錯誤',
                    text: '扣除點數已超過目前點數',
                    showCancelButton: true,
                });
                return;
            }

            if(index == selectedEmployee.value){
                Swal.fire({
                    icon: 'warning',
                    title: '錯誤',
                    text: '轉出轉入不能為同一人',
                    showCancelButton: true,
                });
                return;
            }
            if (usePoints) {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: "btn btn-outline-red",
                        confirmButton: "btn btn-outline-primary",
                    },
                    buttonsStyling: false,
                });
                swalWithBootstrapButtons
                    .fire({
                        icon: 'warning',
                        title: '你確定要更新嗎 ?',
                        showCancelButton: true,
                        cancelButtonText: '取消',
                        confirmButtonText: '確定',
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            // index = employee_id
                            const response = fetch(apiUrl + '/' + index, {
                                headers: {
                                    'X-Requested-With': 'XMLHttpRequest',
                                    'X-CSRF-TOKEN': csrfToken,
                                    'Accept': 'application/json',
                                },
                                method: 'PATCH',
                                body: new URLSearchParams({
                                    'employee_id': selectedEmployee.value,
                                    'use_point': parseInt(employee.use_point, 10)
                                }),
                            }).then((response) => {
                                if (!response.ok) {
                                    return response.json().then((json) => {
                                        // 將錯誤訊息拋出
                                        throw new Error(JSON.stringify(json));
                                    });
                                }
                                return response.json(); // 轉換回應為 JSON 格式
                            }).then((json) => {
                                getUserList();
                                getEmployeeList();
                                getEmployeeList
                                Swal.fire({
                                    title: json.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                                employee.point = json.point; // 更新計算好的點數
                                employee.use_point = ''; // 清空輸入框
                                getDeductedPoints();
                            }).catch((error) => {
                                try {
                                    const errorObj = JSON.parse(error.message); // 將錯誤的字串解析為物件
                                    if (errorObj.errors && Array.isArray(errorObj.errors)) {
                                        // 如果有 errors，顯示每個錯誤訊息
                                        errorObj.errors.forEach(message => {
                                            $.notify(message, 'danger');
                                        });
                                    } else {
                                        // 否則顯示通用錯誤訊息
                                        $.notify(errorObj.message, 'danger');
                                    }
                                } catch (e) {
                                    // 如果無法解析錯誤，顯示通用的錯誤訊息
                                    $.notify('資料錯誤', 'danger');
                                }
                            });
                        }
                    });
            }
        };

        /**
         * 是否為加盟店共用點數
         */
        const isStore = () => {
            const regex = /^[A-Za-z][0-9]{3}$/;
            return regex.test(String(selectedEmployee.value));
        };

        const isStoreError = () => {
            return (isStore() && isPointError);
        }

        const init = () => {
            getUserStore();
            getStoreList();
        };

        /**
         * 取得轉出加盟店
         */
        const getUserStore = async () => {
            const response = await fetch(apiUrl + '?type=getUserStore', {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                },
                method: 'GET',
            }).then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        // 將錯誤訊息拋出
                        throw new Error(JSON.stringify(json));
                    });
                }
                return response.json(); // 轉換回應為 JSON 格式
            }).then((json) => {
                selectedStore.value = json.store_id
                selectedStoreList.value = json.store_id
                userStore.value = json.store
                userList.value = json.employees
                changeEmployee();
            }).catch((error) => {
                $.notify('資料錯誤', 'danger');
            });

        }

        /**
         * 取得可轉出經紀人
         */
        const getUserList = () => {
            // 取消前一個請求（如果存在的話）
            if (abortController) {
                abortController.abort();
            }

            // 創建一個新的 AbortController 實例
            abortController = new AbortController();
            const signal = abortController.signal;

            userList.value = {};
            const response = fetch(apiUrl + '?type=getUserList&store_id=' + selectedStore.value, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                },
                method: 'GET',
                signal: signal // 傳入信號，允許取消請求
            }).then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        // 將錯誤訊息拋出
                        throw new Error(JSON.stringify(json));
                    });
                }
                return response.json(); // 轉換回應為 JSON 格式
            }).then((json) => {
                userList.value = json
                changeEmployee();
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    $.notify('資料錯誤', 'danger'); // 處理其他錯誤
                }
            });

        }

        /**
         * 取得可轉加盟店
         */
        const getStoreList = async () => {
            const response = await fetch(apiUrl + '?type=getStoreList', {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                },
                method: 'GET',
            }).then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        // 將錯誤訊息拋出
                        throw new Error(JSON.stringify(json));
                    });
                }
                return response.json(); // 轉換回應為 JSON 格式
            }).then((json) => {
                storeList.value = json
                const first_store = Object.values(storeList.value)[0];
                selectedStoreList.value = first_store.store_id;
                changeStoreList();
            }).catch((error) => {
                notify('資料錯誤', 'danger');
            });

        }

        /**
         * 變更轉入加盟店
         */
        const changeStoreList = () => {
            const store_id = selectedStoreList.value;
            const store = storeList.value[store_id];
            const first_store = Object.values(storeList.value)[0];
            if (store === undefined && first_store !== undefined) {
                selectedStoreList.value = first_store.store_id;   // 取選單第一個
            }
            getEmployeeList();
        };

        /**
         * 取得轉入經紀人名單
         */
        const getEmployeeList = () => {
            // 取消前一個請求（如果存在的話）
            if (abortController2) {
                abortController2.abort();
            }

            // 創建一個新的 AbortController 實例
            abortController2 = new AbortController();
            const signal2 = abortController2.signal;

            employeeList.value = [];

            const response = fetch(apiUrl + '?type=getEmployeeList&store_id=' + selectedStoreList.value, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': csrfToken,
                    'Accept': 'application/json',
                },
                method: 'GET',
                signal: signal2 // 傳入信號，允許取消請求
            }).then((response) => {
                if (!response.ok) {
                    return response.json().then((json) => {
                        // 將錯誤訊息拋出
                        throw new Error(JSON.stringify(json));
                    });
                }
                return response.json(); // 轉換回應為 JSON 格式
            }).then((json) => {
                employeeList.value = json
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    $.notify('資料錯誤', 'danger');
                }
            });
        }

        /**
         * 訊息通知
         *
         * @param {string} message 訊息內容
         * @param {'default'|'toast'|'info'|'success'|'danger'|'warning'} type 訊息型態
         *  - `'default'`: 預設的訊息通知樣式
         *  - `'toast'`: 簡短的提示通知
         *  - `'info'`: 用於顯示資訊型通知
         *  - `'success'`: 用於顯示成功型通知
         *  - `'danger'`: 用於顯示錯誤或危險的通知
         *  - `'warning'`: 用於顯示警告型通知
         */
        const notify = (message, type) => {
            $.notify({
                    message: message
                },
                {
                    type: type,
                    allow_dismiss: false,
                    newest_on_top: false,
                    mouse_over: false,
                    showProgressbar: false,
                    spacing: 10,
                    timer: 500,
                    placement: {
                        from: 'top',
                        align: 'right'
                    },
                    offset: {
                        x: 30,
                        y: 30
                    },
                    delay: 1000,
                    z_index: 10000,
                    animate: {
                        enter: 'animated fadeIn',
                        exit: 'animated bounce'
                    }
                })
        }
        onMounted(() => {
            init();
        });

        return {
            userStore,
            userList,
            selectedStore,
            selectedEmployee,

            totalPoints,
            deductedPoints,

            storeList,
            employeeList,
            selectedStoreList,

            isStore,
            isStoreError,

            init,
            changeStoret,
            changeEmployee,
            previewPoint,
            updatePoint,
            getEmployeeList,
            changeStoreList,
        };
    },
});

$allocate_points.mount("#allocate_points");

